<template>
  <div class="content-wrapper">
    <h1>Profile</h1>

    <div class="w-100 grid">
      <b-row>
        <b-col cols="12" md="8">
          <h3>Edit profile</h3>

          <b-card class="my-4">
            <b-row>
              <b-col cols="12" md="4">
                <div
                  class="upload-photo-cicrle"
                  v-bind:style="{
                    backgroundImage: 'url(' + backgroundImage() + ')',
                  }"
                  style="min-width: 150px; min-height: 150px;"
                >
                  <b-avatar
                    v-if="tempItem.avatar_url.length == 0"
                    size="6rem"
                  ></b-avatar>

                  <b-form-file
                    class="button-file photo-button"
                    v-model="file_photo"
                    accept="image/jpeg, image/png, image/gif"
                  >
                    <template #placeholder>
                      <b-icon-camera-fill />
                    </template>
                    <template #file-name>
                      <b-icon-camera-fill />
                    </template>
                  </b-form-file>
                  <b-icon
                    v-if="loading.file"
                    class="loading-file"
                    icon="arrow-clockwise"
                    animation="spin"
                    font-scale="2"
                  ></b-icon>
                </div>
              </b-col>
              <b-col cols="12" md="8">
                <b-form @submit.prevent="handleEditProfile">
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group label="First Name" label-for="name">
                        <b-form-input
                          id="name"
                          v-model="tempItem.nome"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" md="6">
                      <b-form-group label="Last Name" label-for="surname">
                        <b-form-input
                          id="surname"
                          v-model="tempItem.cognome"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12">
                      <b-form-group label="Email" label-for="email">
                        <b-form-input
                          id="email"
                          v-model="tempItem.email"
                          type="email"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <div class="w-100 flex justify-content-end mt-4">
                    <b-button
                      variant="primary"
                      type="submit"
                      :disabled="loading.editProfile"
                      >Save
                    </b-button>
                  </div>
                </b-form>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col cols="12" md="4">
          <h3>Change password</h3>

          <b-card class=" my-4">
            <b-form @submit.prevent="handleChangePassword">
              <b-form-group
                label="New Password"
                label-for="new-password"
                description="Enter your new password."
              >
                <b-input-group>
                  <b-form-input
                    id="new-password"
                    v-model="newPassword"
                    :type="showNewPassword ? 'text' : 'password'"
                    required
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      @click="togglePasswordVisibility('new')"
                      variant="outline-secondary"
                    >
                      <b-icon
                        :icon="showNewPassword ? 'eye-slash' : 'eye'"
                      ></b-icon>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-form-group
                label="Confirm New Password"
                label-for="confirm-new-password"
                description="Re-enter your new password to confirm."
              >
                <b-input-group>
                  <b-form-input
                    id="confirm-new-password"
                    v-model="confirmNewPassword"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    required
                    :state="passwordMatch"
                    :feedback="passwordMatch ? '' : 'Passwords do not match'"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      @click="togglePasswordVisibility('confirm')"
                      variant="outline-secondary"
                    >
                      <b-icon
                        :icon="showConfirmPassword ? 'eye-slash' : 'eye'"
                      ></b-icon>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <div class="w-100 flex justify-content-end mt-4">
                <b-button
                  variant="primary"
                  type="submit"
                  :disabled="!canSubmit || loading.changePassword"
                >
                  Change Password
                </b-button>
              </div>
              <b-spinner
                v-if="loading.changePassword"
                small
                type="grow"
                variant="light"
                class="ml-2"
              ></b-spinner>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import AxiosService from "../axiosServices/AxiosService";

export default {
  name: "Profile",
  data() {
    return {
      loading: {
        changePassword: false,
        file: false,
        editProfile: false,
      },
      newPassword: "",
      confirmNewPassword: "",
      showNewPassword: false,
      showConfirmPassword: false,

      tempItem: {
        avatar_url: "",
      },

      file_photo: null,
    };
  },
  created() {
    this.tempItem = { ...this.currentUser };
  },
  computed: {
    file_photo_url() {
      return this.file_photo ? URL.createObjectURL(this.file_photo) : "";
    },
    currentUser() {
      return this.$store.getters.loggedUser;
    },
    passwordMatch() {
      return this.newPassword === this.confirmNewPassword;
    },
    canSubmit() {
      return this.newPassword && this.confirmNewPassword && this.passwordMatch;
    },
  },
  methods: {
    async handleEditProfile() {
      this.loading.editProfile = true;

      const service = new AxiosService("UserBo");

      if (this.file_photo) {
        const formData = new FormData();
        formData.append("pic", this.file_photo);
        let imageName = await service
          .uploadFile(formData)
          .then((res) => res.data)
          .finally(() => {
            this.loading.file = false;
          });
        let imageUrl = this.$driveAddress + imageName;
        this.tempItem.avatar_url = imageUrl.replaceAll(" ", "%20");
      }

      try {
        await service.update(this.tempItem);

        this.$bvToast.toast("Profile updated successfully", {
          title: "Success",
          variant: "success",
          solid: true,
        });
      } catch (error) {
        console.error("Error updating profile:", error);
        this.$bvToast.toast("Error updating profile", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.loading.editProfile = false;
      }
    },
    backgroundImage() {
      return this.file_photo_url || this.tempItem.avatar_url;
    },
    async handleChangePassword() {
      if (!this.canSubmit) {
        return;
      }

      const service = new AxiosService("User");
      this.loading.changePassword = true;

      try {
        await service.putCustomEndpoint(
          `UserBo/ChangePassword/${this.currentUser.id}/${this.newPassword}`
        );
        this.$bvToast.toast("Password changed successfully", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.newPassword = "";
        this.confirmNewPassword = "";
      } catch (error) {
        console.error("Error changing password:", error);
        this.$bvToast.toast("Error changing password", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.loading.changePassword = false;
      }
    },
    togglePasswordVisibility(field) {
      if (field === "new") {
        this.showNewPassword = !this.showNewPassword;
      } else if (field === "confirm") {
        this.showConfirmPassword = !this.showConfirmPassword;
      }
    },
  },
};
</script>
